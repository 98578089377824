<template>
  <div class="user_info">
    <div class="title">
      <span>个人资料</span>
    </div>
    <el-form v-if="form" class="content" ref="form" :model="form" label-width="120px">
      <el-form-item label="姓名：">
        <el-col :span="3">
          <el-input v-model="form.user_name" placeholder="请填写姓名" />
        </el-col>
      </el-form-item>

      <el-form-item label="性别：">
        <el-col :span="3">
          <el-select v-model.number="form.is_sex" placeholder="请选择">
            <el-option :key="1" label="男" :value="1"> </el-option>
            <el-option :key="0" label="女" :value="0"> </el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item label="民族：">
        <el-col :span="3">
          <el-select v-model="form.minzu" placeholder="请选择">
            <el-option :key="1" label="汉" :value="1"> </el-option>
            <el-option :key="0" label="少数民族" :value="0"> </el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item label="首选科类：">
        <el-col :span="5">
          <el-checkbox-group v-if="form.subject" v-model="subject_list" :max="1" size="medium" disabled  @change="getSubject">
            <el-checkbox :label="4">历史</el-checkbox>
            <el-checkbox :label="1">物理</el-checkbox>
          </el-checkbox-group>

          <el-checkbox-group v-else v-model="subject_list" :max="1" size="medium" @change="getSubject">
            <el-checkbox :label="4">历史</el-checkbox>
            <el-checkbox :label="1">物理</el-checkbox>
          </el-checkbox-group>

        </el-col>
      </el-form-item>

      <el-form-item label="再选科类：">
        <el-col :span="12">
          <el-checkbox-group v-model="other_subject" v-if="other_subject.length > 1" disabled :max="2">
            <el-checkbox :label="2">化学</el-checkbox>
            <el-checkbox :label="3">生物</el-checkbox>
            <el-checkbox :label="6">政治</el-checkbox>
            <el-checkbox :label="5">地理</el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="other_subject" v-else :max="2">
            <el-checkbox :label="2">化学</el-checkbox>
            <el-checkbox :label="3">生物</el-checkbox>
            <el-checkbox :label="6">政治</el-checkbox>
            <el-checkbox :label="5">地理</el-checkbox>
          </el-checkbox-group>
        </el-col>
      </el-form-item>

      <el-form-item label="高考成绩：">

        <el-col :span="6">
          <el-button size="mini" @click="dialogVisible = true">输入高考成绩</el-button>
        </el-col>
      </el-form-item>
      <el-form-item label="专业成绩：" v-if="form.is_vip == 2">
        <el-col :span="6">
          <el-input v-model="form.z_score" type="number" placeholder="输入分数" />
        </el-col>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-col :span="10">
          {{ form.mobile | filtersMobile }}
        </el-col>
      </el-form-item>

      <el-form-item label="所在省市：">
        <el-col :span="4">
          <el-input v-model="form.province" placeholder="河南省" disabled />
        </el-col>
        <el-col :span="4">
          <el-select v-model="form.city" placeholder="请选择" @change="selectCity($event)">
            <el-option v-for="item in cityList" :key="item.label" :label="item.label" :value="item.value" />
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="form.district" placeholder="请选择" @change="selectSchool($event)">
            <el-option v-for="item in districtList" :key="item.label" :label="item.label" :value="item.value" />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="就读中学：">
        <el-col class="school">
          <el-select v-model="form.school" placeholder="请选择">
            <el-option v-for="item in schoolList" :key="item.label" :label="item.label" :value="item.value" />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="就读班级：">
        <el-col :span="4">
          <el-input v-model="form.grade" placeholder="年级" />
        </el-col>
        <el-col :span="4">
          <el-input v-model="form.class" placeholder="班级" />
        </el-col>
      </el-form-item>
      <div v-if="more">
        <div class="title" style="margin-bottom: 15px">个人信息补充</div>
        <el-row>
          <el-col :span="6">
            <el-form-item label="语文：">
              <el-input v-model="form.chinese" placeholder="输入分数" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="数学：">
              <el-input v-model="form.math" placeholder="输入分数" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="英语：">
              <el-input v-model="form.english" placeholder="输入分数" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="听力：">
              <el-input v-model="form.tingli" placeholder="输入分数" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="综合成绩：">
              <el-input v-model="form.danke" placeholder="输入分数" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学科倾向：">
              <el-input v-model="form.xkqx" placeholder="学科倾向" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="应往届：">
              <el-input v-model="form.ywj" placeholder="应往届" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否失信：">
              <el-radio-group v-model="form.shixin">
                <el-radio :label="'1'">是</el-radio>
                <el-radio :label="'2'">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学费要求：">
              <el-input v-model="form.xuefei" placeholder="学费要求" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="身体受限：">
              <el-input v-model="form.stsx" placeholder="体检限制" />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="title" style="margin-bottom: 15px">意向省份</div>
        <el-form-item label="选择城市：">
          <el-select v-model="form.like_city" multiple placeholder="请选择" style="width: 480px">
            <el-option v-for="item in city" :key="item.city" :label="item.city" :value="item.city">
            </el-option>
          </el-select>
        </el-form-item>

        <div class="title" style="margin-bottom: 15px">排除省份</div>

        <el-form-item label="选择城市：">
          <el-select v-model="form.no_like_city" multiple placeholder="请选择" style="width: 480px">
            <el-option v-for="item in city" :key="item.city" :label="item.city" :value="item.city">
            </el-option>
          </el-select>
        </el-form-item>

        <div class="title" style="margin-bottom: 15px">特殊升学要求</div>
        <el-form-item label="选择：">
          <el-select v-model="form.special_request" multiple placeholder="请选择" style="width: 480px">
            <el-option v-for="item in spSpecialty" :key="item.name" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="form.tssxbz" placeholder="备注" type="textarea" style="width: 300px; margin-bottom: 10px" />
        </el-form-item>

        <div class="title" style="margin-bottom: 15px">喜欢专业</div>
        <el-form-item label="喜欢专业：">
          <el-input type="textarea" style="width: 300px; margin-bottom: 10px" placeholder="请输入内容"
            v-model="form.like_sp" />
        </el-form-item>
        <div class="title" style="margin-bottom: 15px">排除专业</div>
        <el-form-item label="排除专业：">
          <el-input type="textarea" style="width: 300px; margin-bottom: 10px" placeholder="请输入内容"
            v-model="form.no_like_sp" />
        </el-form-item>
      </div>
      <el-form-item class="more">
        <div class="btn1" @click="more = !more">
          <div v-if="!more">展开更多信息</div>
          <div v-if="more">收起</div>
        </div>
        <div class="btn" @click="onSubmit">确认修改</div>
      </el-form-item>
    </el-form>

    <el-dialog title="修改分数" :visible.sync="dialogVisible" width="30%">
      <el-form v-if="form" ref="form" :model="form" label-width="120px">
        <el-form-item label="高考成绩：">
          <el-col :span="24" v-if="form.change != 1">
            <el-input v-if="form" v-model="form.score" type="number" placeholder="输入高考成绩" @change="getRanking" />
          </el-col>
          <el-col :span="24" v-else>
            <el-input v-if="form" disabled v-model="form.score" type="number" placeholder="输入高考成绩"
              @change="getRanking" />
          </el-col>
          <span style="font-size:12px;color:red;margin-bottom:20px">输入真实高考成绩之后不可修改。请谨慎修改</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editScore">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import height_school from "@/assets/data/height_school.js";
import city from "@/assets/data/conditional-search.js";
import { mapState, mapMutations } from "vuex";
import w_score from "@/assets/data/w_score.js";
import l_score from "@/assets/data/l_score.js";

export default {
  name: "Info",
  data() {
    return {
      cityList: [],
      districtList: [],
      schoolList: [],
      city: city.city,
      dialogVisible: false,
      form: null,
      spSpecialty: [
        { id: 0, name: "无" },
        { id: 1, name: "艺术类" },
        { id: 2, name: "体育类" },
        { id: 3, name: "军队院校" },
        { id: 4, name: "定向培养士官" },
        { id: 5, name: "公安院校" },
        { id: 6, name: "司法类" },
        { id: 7, name: "香港高校" },
        { id: 8, name: "澳门高校" },
        { id: 9, name: "定向就业" },
        { id: 10, name: "少数民族预科班" },
      ],
      other_subject: [],
      subject_list: [],
      more: false,
    };
  },
  filters: {
    filtersMobile(item) {
      return item.replace(item.substr(3, 4), "****");
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },

  mounted() {
    this.cityList = height_school;
    this.$fecth.post("user_info/getUserInfo").then((res) => {
      if (res) {
        localStorage.setItem("UserInfo", JSON.stringify(res));
        delete res.profile;
        this.form = res;
        if (res.other_subject) {
          this.other_subject = JSON.parse(res.other_subject)
        }
        if (res.subject) {
          this.subject_list = [res.subject]
        }
        this.setInfo(this.form);
      }

    });
  },
  methods: {
    ...mapMutations(["setInfo"]),
    onSubmit() {
      this.form.other_subject = JSON.stringify(this.other_subject)
      this.$fecth.post("user_info/updateUserInfo", this.form).then((res) => {
        if (res) {
          this.$notify({
            showClose: true,
            message: "更新成功！",
            type: "success",
          });
          this.setInfo(this.form);
          localStorage.setItem("UserInfo", JSON.stringify(this.form));
        }
      });
    },
    getSubject() {
      console.log(this.subject_list)
      if (this.subject_list.length > 0) {

        this.subject = this.subject_list[0]
        if (this.subject == 4) {
          this.form.is_wenli = 2
        } else {
          this.form.is_wenli = 1
        }
        this.getRanking()
      }
    },
    getRanking() {
      let rankData = [];
      if (this.form.is_wenli == 1) {
        rankData = w_score["2024"];
      } else {
        rankData = l_score["2024"];
      }
      this.form.ranking = "";
      rankData.forEach((item) => {
        if (item.score == this.form.score) {
          this.form.ranking = item.ranking;
        }
      });
    },
    handleAvatarSuccess(res) {
      if (res.code === "200") {
        this.form.head_img = res.data;
      } else {
        this.$notify({
          showClose: true,
          message: "上传图片失败！",
          type: "error",
        });
      }
    },
    editScore() {
      this.$fecth.post("user_info/changeScore", this.form).then((res) => {
        if (res) {
          this.$notify({
            showClose: true,
            message: "更新成功！",
            type: "success",
          });
          this.form.change = 1
          this.setInfo(this.form);
          localStorage.setItem("UserInfo", JSON.stringify(this.form));
        }
        this.dialogVisible = false
      });
    },
    selectCity(value) {
      let obj = {};
      obj = this.cityList.find((item) => {
        return item.label === value; // 筛选出匹配数据
      });
      this.form.district = null;
      this.districtList = obj.children;
    },
    selectSchool(value) {
      let obj = {};
      obj = this.districtList.find((item) => {
        return item.label === value; // 筛选出匹配数据
      });
      this.form.school = null;
      this.schoolList = obj.children;
    },
  },
};
</script>
<style lang="less" scoped>
.user_info {
  .title {
    font-size: 16px;
    height: 50px;
    line-height: 57px;
    padding: 0 30px;
    border-bottom: #ddd solid 1px;
  }

  .content {
    padding: 20px;
    min-height: 470px;
  }

  .more {
    margin-top: 40px;

    .btn1 {
      float: left;
      margin-left: 250px;
      font-size: 16px;
      color: #ddd;
    }
  }
}
</style>

<style lang="less">
.user_info {
  .el-radio__label {
    font-size: 16px;
  }

  font-size: 16px;
  background-color: #ffffff;

  .el-form-item__label {
    font-size: 16px;
  }

  .el-input__inner {
    line-height: 27px;
    height: 27px;
    background-color: #f4f4f4;
  }

  .el-form-item {
    margin-bottom: 1px;
  }

  .school {
    .el-input__inner {
      width: 409px;
    }
  }

  .btn {
    background: #288df9;
    border-radius: 3px;
    padding: 2px 15px;
    height: 20px;
    line-height: 20px;
    width: 57px;
    margin-left: 600px;
    color: white;
  }
}
</style>
